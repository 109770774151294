import React from "react";
import { motion } from "framer-motion";

const About = () => {
  const skills = {
    frontend: ["React", "JavaScript", "TypeScript", "Tailwind CSS", "Next.js"],
    backend: ["Node.js", "Python", "MongoDB", "PostgreSQL", "AWS"],
    tools: ["Git", "Docker", "Figma", "Jest", "CI/CD"]
  };

  const milestones = [
    {
      year: "2023",
      title: "Senior Full Stack Developer",
      description: "Leading development teams and architecting scalable solutions",
      achievements: [
        "Led team of 5 developers",
        "Implemented microservices architecture",
        "Reduced deployment time by 40%"
      ],
      tech: ["React", "Node.js", "AWS", "Docker"]
    },
    {
      year: "2021",
      title: "Frontend Specialist",
      description: "Focused on creating responsive and accessible web applications",
      achievements: [
        "Developed 20+ client projects",
        "Improved site performance by 60%",
        "Implemented CI/CD pipelines"
      ],
      tech: ["React", "TypeScript", "Redux", "Jest"]
    },
    {
      year: "2019",
      title: "Started Coding Journey",
      description: "Began with web development fundamentals and never looked back",
      achievements: [
        "Completed full-stack bootcamp",
        "Built first client project",
        "Started freelancing"
      ],
      tech: ["JavaScript", "HTML/CSS", "Node.js"]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-900">
      {/* About Hero Section */}
      <section className="relative min-h-[80vh] bg-gray-900 flex items-center py-24 md:py-32">
        <div className="absolute inset-0">
          <div className="absolute inset-0 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 opacity-90"></div>
          {/* Add subtle grid pattern */}
          <div className="absolute inset-0 bg-[linear-gradient(to_right,#808080_1px,transparent_1px),linear-gradient(to_bottom,#808080_1px,transparent_1px)] bg-[size:4rem_4rem] [mask-image:radial-gradient(ellipse_60%_50%_at_50%_0%,#000_70%,transparent_110%)] opacity-[0.03]"></div>
        </div>
        
        <div className="relative z-10 container mx-auto px-6 md:px-8">
          <div className="max-w-6xl mx-auto">
            <div className="grid md:grid-cols-2 gap-12 lg:gap-16 items-center">
              {/* Text Content */}
              <motion.div
                initial={{ opacity: 0, x: -30 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                className="space-y-8 md:pr-8"
              >
                <h1 className="text-4xl md:text-6xl font-bold text-white mb-8">
                  About <span className="text-cyan-400">Me</span>
                </h1>
                <div className="space-y-6">
                  <p className="text-xl text-gray-300 leading-relaxed">
                    Full Stack Developer with a passion for creating innovative web solutions
                    and scalable applications.
                  </p>
                  <p className="text-lg text-gray-400 leading-relaxed">
                    With 4+ years of experience in web development, I specialize in
                    building modern applications using cutting-edge technologies.
                  </p>
                </div>
                
                {/* Quick Stats */}
                <div className="grid grid-cols-2 gap-6 mt-12 pt-4">
                  {[
                    { number: "4+", label: "Years Experience" },
                    { number: "50+", label: "Projects Completed" },
                    { number: "20+", label: "Happy Clients" },
                    { number: "100%", label: "Client Satisfaction" }
                  ].map((stat, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className="bg-gray-800/50 backdrop-blur-sm border border-cyan-400/20 rounded-xl p-6 text-center group hover:border-cyan-400/50 transition-all duration-300"
                    >
                      <span className="block text-3xl font-bold text-cyan-400 mb-2 group-hover:scale-110 transition-transform duration-300">
                        {stat.number}
                      </span>
                      <span className="text-gray-400 text-sm">
                        {stat.label}
                      </span>
                    </motion.div>
                  ))}
                </div>
              </motion.div>

              {/* Profile/Skills Preview */}
              <motion.div
                initial={{ opacity: 0, x: 30 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                className="bg-gray-800/50 backdrop-blur-sm border border-cyan-400/20 rounded-xl p-8 md:p-10"
              >
                <div className="space-y-8">
                  <div className="flex items-center space-x-6">
                    <div className="w-16 h-16 bg-cyan-400 rounded-full flex items-center justify-center">
                      <svg className="w-8 h-8 text-gray-900" fill="currentColor" viewBox="0 0 20 20">
                        <path d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" />
                      </svg>
                    </div>
                    <div>
                      <h3 className="text-2xl font-semibold text-white">Samuel H. Seifu</h3>
                      <p className="text-cyan-400 text-lg">Full Stack Developer</p>
                    </div>
                  </div>
                  
                  <div className="space-y-6">
                    <div className="flex items-center space-x-4">
                      <svg className="w-6 h-6 text-cyan-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 13.255A23.931 23.931 0 0112 15c-3.183 0-6.22-.62-9-1.745M16 6V4a2 2 0 00-2-2h-4a2 2 0 00-2 2v2m4 6h.01M5 20h14a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg>
                      <span className="text-gray-300 text-lg">Available for freelance</span>
                    </div>
                    <div className="flex items-center space-x-4">
                      <svg className="w-6 h-6 text-cyan-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                      <span className="text-gray-300 text-lg">Remote / Worldwide</span>
                    </div>
                  </div>

                  <div className="pt-8 border-t border-gray-700">
                    <div className="flex flex-wrap gap-3">
                      {["React", "Node.js", "TypeScript", "AWS"].map((tech, i) => (
                        <span
                          key={i}
                          className="px-4 py-2 bg-gray-900/60 text-cyan-400 text-sm rounded-full border border-cyan-400/20 hover:border-cyan-400 transition-all duration-300"
                        >
                          {tech}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </section>

      {/* Skills Section */}
      <section className="py-24 bg-gray-800">
        <div className="container mx-auto px-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="max-w-6xl mx-auto"
          >
            <h2 className="text-4xl font-bold text-white mb-20 text-center">
              Technical <span className="text-cyan-400">Expertise</span>
            </h2>
            <div className="grid md:grid-cols-3 gap-10">
              {Object.entries(skills).map(([category, skillList], idx) => (
                <motion.div
                  key={category}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: idx * 0.2 }}
                  className="bg-gray-900/50 backdrop-blur-sm p-8 rounded-2xl border border-cyan-400/20 hover:border-cyan-400/50 transition-all duration-500 group"
                >
                  <div className="mb-8 flex items-center justify-between">
                    <h3 className="text-2xl font-bold text-cyan-400 capitalize">
                      {category}
                    </h3>
                    <div className="w-12 h-12 rounded-xl bg-cyan-400/10 flex items-center justify-center group-hover:scale-110 transition-transform duration-300">
                      {category === 'frontend' && (
                        <svg className="w-6 h-6 text-cyan-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                        </svg>
                      )}
                      {category === 'backend' && (
                        <svg className="w-6 h-6 text-cyan-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 12h14M5 12a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v4a2 2 0 01-2 2M5 12a2 2 0 00-2 2v4a2 2 0 002 2h14a2 2 0 002-2v-4a2 2 0 00-2-2m-2-4h.01M17 16h.01" />
                        </svg>
                      )}
                      {category === 'tools' && (
                        <svg className="w-6 h-6 text-cyan-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                      )}
                    </div>
                  </div>
                  <div className="space-y-4">
                    {skillList.map((skill, i) => (
                      <motion.div
                        key={skill}
                        initial={{ opacity: 0, x: -20 }}
                        animate={{ opacity: 1, x: 0 }}
                        transition={{ delay: idx * 0.2 + i * 0.1 }}
                        className="flex items-center bg-gray-800/50 p-4 rounded-xl hover:bg-gray-700/50 transition-all duration-300 group/skill"
                      >
                        <div className="flex-1 flex items-center space-x-4">
                          <span className="w-2 h-2 bg-cyan-400 rounded-full group-hover/skill:scale-150 transition-transform duration-300" />
                          <span className="text-gray-300 group-hover/skill:text-cyan-400 transition-colors duration-300 text-lg">
                            {skill}
                          </span>
                        </div>
                        <div className="opacity-0 group-hover/skill:opacity-100 transition-opacity duration-300">
                          <svg className="w-5 h-5 text-cyan-400" fill="currentColor" viewBox="0 0 20 20">
                            <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                          </svg>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </section>

      {/* Timeline Section */}
      <section className="py-32 bg-gray-900">
        <div className="container mx-auto px-6">
          <motion.h2 
            className="text-4xl font-bold text-white mb-24 text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            Professional <span className="text-cyan-400">Journey</span>
          </motion.h2>
          <div className="max-w-6xl mx-auto">
            {milestones.map((milestone, index) => (
              <motion.div
                key={milestone.year}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: index * 0.3 }}
                className="relative mb-40 last:mb-0"
              >
                {/* Year and Line */}
                <div className="absolute left-0 w-full h-[2px] bg-gradient-to-r from-transparent via-cyan-400 to-transparent top-16">
                  <div className="absolute -top-16 left-0 md:left-12">
                    <motion.div
                      whileHover={{ scale: 1.1, rotate: -3 }}
                      className="relative"
                    >
                      <div className="absolute inset-0 bg-cyan-400 rounded-2xl blur-lg opacity-20"></div>
                      <div className="relative bg-gray-800/80 backdrop-blur-sm px-8 py-6 rounded-2xl border-2 border-cyan-400/50">
                        <span className="text-6xl font-bold bg-gradient-to-r from-cyan-400 to-cyan-300 bg-clip-text text-transparent">
                          {milestone.year}
                        </span>
                      </div>
                    </motion.div>
                  </div>
                </div>

                {/* Content Card */}
                <div className="ml-0 md:ml-48 relative mt-32">
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    className="relative group"
                  >
                    <div className="absolute -inset-1 bg-gradient-to-r from-cyan-400 to-cyan-300 rounded-2xl blur opacity-20 group-hover:opacity-30 transition duration-500"></div>
                    <div className="relative bg-gray-800/80 backdrop-blur-sm rounded-xl p-8 border border-cyan-400/20 shadow-xl">
                      {/* Header */}
                      <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-10">
                        <div className="space-y-2">
                          <h3 className="text-3xl font-bold text-white group-hover:text-cyan-400 transition-colors duration-300">
                            {milestone.title}
                          </h3>
                          <p className="text-gray-400">Role & Responsibilities</p>
                        </div>
                        <div className="flex flex-wrap gap-2 mt-4 md:mt-0">
                          {milestone.tech.map((tech, i) => (
                            <motion.span
                              key={i}
                              whileHover={{ scale: 1.05 }}
                              className="px-4 py-1.5 bg-gray-900/80 text-cyan-400 rounded-full text-sm border border-cyan-400/20 hover:border-cyan-400 transition-all duration-300"
                            >
                              {tech}
                            </motion.span>
                          ))}
                        </div>
                      </div>

                      {/* Description */}
                      <p className="text-gray-300 mb-10 text-lg leading-relaxed">
                        {milestone.description}
                      </p>

                      {/* Achievements Grid */}
                      <div className="relative">
                        <div className="absolute -inset-1 bg-gradient-to-r from-cyan-400/20 to-cyan-300/20 rounded-xl blur-lg opacity-20"></div>
                        <div className="relative bg-gray-900/40 backdrop-blur-sm rounded-xl p-8 border border-cyan-400/10">
                          <h4 className="text-xl font-semibold text-cyan-400 mb-8 flex items-center">
                            <svg className="w-6 h-6 mr-3" fill="currentColor" viewBox="0 0 20 20">
                              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"/>
                            </svg>
                            Key Achievements
                          </h4>
                          <div className="grid md:grid-cols-2 gap-8">
                            {milestone.achievements.map((achievement, i) => (
                              <motion.div
                                key={i}
                                initial={{ opacity: 0, x: -20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ delay: i * 0.1 }}
                                className="flex items-start space-x-4 group/achievement"
                              >
                                <div className="relative mt-2">
                                  <div className="absolute inset-0 bg-cyan-400 rounded-full blur opacity-40 group-hover/achievement:opacity-100 transition duration-300"></div>
                                  <div className="relative w-2 h-2 bg-cyan-400 rounded-full group-hover/achievement:scale-150 transition-all duration-300" />
                                </div>
                                <span className="text-gray-300 group-hover/achievement:text-cyan-400 transition-all duration-300">
                                  {achievement}
                                </span>
                              </motion.div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </motion.div>

                  {/* Connector Line */}
                  <div className="absolute left-0 top-0 h-full w-[2px]">
                    <div className="h-full w-full bg-gradient-to-b from-cyan-400 via-cyan-400/50 to-transparent"></div>
                    <div className="absolute top-0 -left-1 w-4 h-4 bg-cyan-400 rounded-full blur-sm"></div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-20 bg-gray-800">
        <motion.div
          className="container mx-auto px-6 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <h2 className="text-3xl font-bold text-white mb-8">Get In Touch</h2>
          <p className="text-xl text-gray-300 mb-12 max-w-2xl mx-auto">
            Interested in working together? Let's discuss your next project.
          </p>
          <div className="flex justify-center gap-6">
            <a
              href="mailto:your.email@example.com"
              className="px-8 py-3 bg-cyan-500 text-gray-900 rounded hover:bg-cyan-400 transition duration-300"
            >
              Send Message
            </a>
            <a
              href="https://linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="px-8 py-3 border border-cyan-500 text-cyan-500 rounded hover:bg-cyan-500 hover:text-gray-900 transition duration-300"
            >
              LinkedIn Profile
            </a>
          </div>
        </motion.div>
      </section>
    </div>
  );
};

export default About;
